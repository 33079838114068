import React from "react";
import styled from "styled-components";

const TextWrapper = styled.div`
  position: relative;
  text-align: center;
`;

const TextContent = styled.span`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  border-bottom: 3px solid #10B981;
  padding: .5rem 1rem;
`;

export default function HeadingText({ children, className }) {
  return (
    <TextWrapper className={`${className}`}>
      <TextContent>{children}</TextContent>
    </TextWrapper>
  );
}
