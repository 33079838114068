import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

const EduItem = styled.div`
  positon: relative;
  width: 100%;
  border: 1px solid ${rgba("black", 0.1)};
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
`;

export default function EducationSection(props) {
  const educationItems = [
    {
      shortName: "SBD Govt College",
      college: "Institute of Cost Accountant of India",
      location: "Jaipur, Rajasthan, India",
      duration: "2016-2020",
      degree: "CMA - Cost and Management Accountant",
    },
    {
      shortName: "SBD Govt College",
      college: "SBD Govt College.",
      location: "Sardarshahar, Churu, Rajasthan, India",
      duration: "2017-2018",
      degree: "Master of Commerce",
    },
    {
      shortName: "SBD Govt College",
      college: "SBD Govt College.",
      location: "Sardarshahar, Churu, Rajasthan, India",
      duration: "2013-2015",
      degree: "Bachelor of Commerce",
    },
  ];

  return (
    <div className="mt-20 p-4">
      {educationItems.map((item, index) => {
        return (
          <EduItem key={index} className="shadow">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <span className="text-green-700 text-xl font-medium">
                  {item.degree}
                </span>
                <span className="text-gray-500">From: {item.college}</span>
                <div className="text-gray-500 mt-3">
                  {item.duration} {item.location}
                </div>
              </div>
            </div>
          </EduItem>
        );
      })}
    </div>
  );
}
