import React from "react";
import { SkillPillWrapper } from "./SkillsSection";

export default function OtherSkills() {
  const skills = [
    "Tally",
    "English Typing",
    "Microsoft Word",
    "Microsoft Excel",
  ];
  return (
    <>
      {skills.map((item) => {
        return (
          <SkillPillWrapper key={item} className="rounded-full">
            {item}
          </SkillPillWrapper>
        );
      })}
    </>
  );
}
