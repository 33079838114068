import React from "react";
import { ChevronUp, Facebook, Instagram, Linkedin, Mail } from "react-feather";
import styled from "styled-components";
const FooterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #fff;
`;

const links = [
  {
    icon: <Instagram />,
    link: "https://www.instagram.com/_deepakswami/",
  },
  {
    icon: <Linkedin className="fill-current" strokeWidth="1" />,
    link: "https://www.linkedin.com/in/cma-deepak-swami-88b85720b/",
  },
  {
    icon: <Mail />,
    link: "mailto:cmadeepakswami@gmail.com",
  },
];

export default function Footer({ scrollToTop }) {
  return (
    <FooterWrapper className="bg-green-600">
      <span className="text-sm">{`Copyright ${new Date().getFullYear()} © CMA Deepak Swami`}</span>
      {/* <ChevronUp onClick={scrollToTop} /> */}
      <div className="flex items-center">
        {links.map((item, index) => {
          return (
            <span key={index} className="mx-2">
              <a href={item.link} target="blank">
                {item.icon}
              </a>
            </span>
          );
        })}
      </div>
    </FooterWrapper>
  );
}
