import React from "react";
import EducationSection from "./components/EducationSection";
import Footer from "./components/Footer";
import HeadingText from "./components/HeadingText";
import OtherSkills from "./components/OtherSkills";
import ExperienceSection from "./components/ExperienceSection";
import SkillsSection from "./components/SkillsSection";

import ProfilePic from "./assets/profile.jpg";

function App() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="w-full h-screen relative overflow-y-auto">
      <div className="h-full bg-green-500 flex items-center justify-center">
        <div
          className="flex items-center flex-col-reverse sm:flex-row justify-center text-white px-5"
          style={{ maxWidth: "992px" }}
        >
          <div className="text-center sm:text-left">
            <div className="text-4xl font-medium">CMA Deepak Swami</div>
            <div className="text-green-50 tracking-widest">
              Cost & Management Accountant
            </div>
            <div className="mt-2 self-center w-full sm:w-3/4 text-sm text-green-50 tracking-wide">
              Looking for an opportunity where I can bring my knowledge and
              skills efficiently which can contribute towards the development of
              organization.
            </div>
            <div className="text-center mt-4 px-4 py-3 bg-white shodow-lg rounded-lg text-green-700 w-full sm:w-56">
              <a
                href="https://drive.google.com/file/d/1-KnApRrEv1uQjaMI_j8xanS7hogN14Id/view?usp=drivesdk"
                target="_blank"
              >
                Download Resume
              </a>
            </div>
          </div>
          <div className="text-left sm:text-center mb-6 sm:mb-0">
            <img
              src={ProfilePic}
              className="w-56 sm:w-96 rounded-full border-white border-4"
              alt="profile"
            />
          </div>
        </div>
      </div>
      <div className="h-screen flex items-center justify-center flex-col">
        <HeadingText>Education</HeadingText>

        <EducationSection />
      </div>
      <div className="mt-4 bg-gray-50">
        <HeadingText className="pt-10">Experience</HeadingText>
        <ExperienceSection />
      </div>

      <div className="mt-4 flex flex-col py-10">
        <HeadingText>Skills</HeadingText>
        <div className="flex flex-wrap mt-6 justify-center w-3/4 self-center">
          <OtherSkills />
        </div>
      </div>
      <Footer scrollToTop={scrollToTop} />
    </div>
  );
}

export default App;
