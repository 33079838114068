import React, { useState } from "react";
import { Briefcase, ChevronLeft } from "react-feather";
import Dialog from "./Dialog";
import Spinner from "./Spinner";

export default function ExperienceSection() {
  const [open, setOpen] = useState(false);
  const [viewProject, setViewProject] = useState({
    loading: false,
    files: [],
    projectInfo: {},
  });

  const getProjectFiles = (index, currentProject) => {
    setViewProject({ projectInfo: {}, loading: true, files: [] });
    const requestOpts = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      "http://bharat.jemsinfo.com/images/getFiles.php?name=" + currentProject,
      requestOpts
    )
      .then((data) => data.json())
      .then((op) => {
        setViewProject({
          projectInfo: projects[index],
          loading: false,
          files: op,
        });
      });
  };

  const projects = [
    {
      name: "Mohan Sharma and Associates",
      about: "CMA Trainee",
    },
    {
      name: "DS and Songara and Associates",
      about: "Accountant",
    },
    {
      name: "I.A.S.E University, Sardarshahar",
      about: "Jr. Accountant",
    }
  ];

  return (
    <div className="flex flex-col p-4 items-center">
      {/* <Dialog isOpen={open}>
        <ChevronLeft onClick={() => setOpen(false)} />
        {!viewProject.loading ? (
          <div className="flex flex-col bg-white h-full w-full">
            <div>{viewProject.projectInfo.name}</div>
            <div>{viewProject.projectInfo.about}</div>
            <div className="flex flex-wrap flex-reverse">
              {viewProject.files.reverse().map((item, index) => {
                // console.log(
                //   `http://bharat.jemsinfo.com/images/${item.filepath}`
                // );
                return (
                  <div key={index} className="m-4">
                    <img
                      className="max-h-96 justify-self-center shadow"
                      src={`http://bharat.jemsinfo.com/images/${item.filepath}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="h-screen flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </Dialog> */}
      {projects.map((item, index) => {
        return (
          <div
            className="flex flex-col bg-white sm:flex-row items-center my-4 shadow rounded-lg w-full sm:w-1/2"
            key={index}
          >
            <div className="hidden sm:flex text-gray-600 w-full sm:w-40 border-b sm:border-b-0  sm:border-r h-40 items-center justify-center">
              <Briefcase className="w-16 h-16" />
            </div>
            <div className="ml-2 flex flex-col p-4 sm:p-0 items-center justify-center w-full">
              <span className="mx-2 font-bold text-lg text-gray-600">
                {item.name}
              </span>
              <span className="mx-2 text-sm text-gray-500">{item.about}</span>
              {/* {item.folder !== "" ? (
                <div>
                  <button
                    onClick={() => {
                      setOpen(true);
                      getProjectFiles(index, item.folder);
                    }}
                  >
                    View Project
                  </button>
                </div>
              ) : null} */}
            </div>
          </div>
        );
      })}
    </div>
  );
}
